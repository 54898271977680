body.clean-hosted-template {
	margin-bottom: 36px;

	.hosted-signup-header {
		background-color: #ECEEEF;
	}

	.navbar {
		padding: 0;
	}

	#signupform .form-wrapper,
	#signupform .order-details-wrapper {
		padding-top: 16px;
	}

	.payment-option {
		padding: 8px;
		background-color: #ECEEEF;
		border: 1px solid #ddd;
	}

	.cc-payment-option {
		border-top-left-radius: 4px;
		border-top-right-radius: 4px;
	}

	.ach-payment-option {
		border-bottom-left-radius: 4px;
		border-bottom-right-radius: 4px;
	}

	.payment-option > label {
		margin-bottom: 0;
	}

	.cc-billing-fields {
		padding: 12px;
		border-left: 1px solid #ddd;
		border-right: 1px solid #ddd;
		border-bottom: 1px solid #ddd;
	}

	.ach-billing-fields {
		padding: 12px;
		border-left: 1px solid #ddd;
		border-right: 1px solid #ddd;
		border-bottom: 1px solid #ddd;
	}

	.btn-more-options {
		background-color: #ECEEEF;
	}

	.ach-more-options-fields {
		display: none;
	}

	/* @todo: pick darker red color for error? */
	.error {
		color: red;
	}

	/* @todo: make field label red when error on submit */
	.error ~ label {
		color: red;
		margin-bottom: 0;
	}

	.expiration-fields {
		display: flex;
		align-items: center;
	}

	.expiration-fields > span {
		padding: 0 10px;
	}

	.btn-more-options {
		margin-top: 24px;
	}

	.form-wrapper {
		@media only screen and (min-width: map-get($grid-breakpoints, lg)) {
			border-right: 1px solid #ECEEEF;
		}
	}

	.order-item {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}

	.order-price {
		text-align: right;
	}

	.order-total-row .order-price {
		color: green;
	}

	.quantity-field {
		margin: 12px 0;
	}

	.order-buttons button {
		width: 100%;
		margin: 0 0 12px 0;
	}

	#coupon_form {
		margin: 12px 0;
	}

	.plan-checkout-header,
	.order-details-header {
		display: none;
	}

	#form-processing {
		display: none;
	}

	#form-error {
		display: none;
	}

	.powered {
		margin-top: 36px;
	}

	@media only screen and (max-width: map_get($grid-breakpoints, lg)) {
		.signup-header {
			display: none;
		}

		.plan-checkout-header,
		.order-details-header {
			display: block;
		}
	}
}
